<template>
    <div class="contact-container">
        <top-bar />
        <div id="launch-page">

            <div class="launch amsterdam">
                <h2>Launch 2025</h2>
                <p>29 november 2025<br>
                20:00 - 23:00</p>
                <p>Free drinks!</p>
                <p>Sovereign House, <br>
                185 East Broadway<br>
                Manhattan NYC</p>
            </div>
            
            <div class="launch berlin">
                <p><img src="/images/assets/launch-2025-nyc.jpg" alt="poster Amsterdam Nude Calendar NYC 2025 launch party" /></p>
            </div>

        </div>
    </div>
</template>

<script>
    import TopBar from "@/components/TopBar";

    export default {
        name: 'Launch',
        components: {
            TopBar,
        },
    };
</script>

<style lang="scss" scoped>
    #launch-page{
        margin: 2em 22px;
        @media (max-width: 1020px){
            margin: 2em 10px;
        }
        color: #aa443c;
        display: flex;
        @media screen and (max-width:  1024px){
            flex-wrap: wrap;
        }

        h2#audiotour{
            margin: 2em 0 .5em;
        }
        .launch{
            flex: 45% 0 0;
            @media screen and (min-width:  1024px){
                &.berlin{
                    margin-left: 5%;
                }
            }
            @media screen and (max-width:  1024px){
                flex:  100% 0 0;
                margin:  0 0 3em;
                max-width: none;
            }
        }

        a.download:link,
        a.download:visited{
            display: block;
            margin: 0;
            font-size: 3em;
            line-height: 1em;
            @media screen and (max-width:  1024px){
                font-size: 2em;
            }
            text-decoration: none;
            font-weight: 900;
            color: #00a850;
            padding: 0 0 0.3em;
            font-family: "HelveticaNeue-75";
            text-shadow:
                0 1px 0px #000,
                1px 0 0px #000,
                1px 2px 1px #000,
                2px 1px 1px #000,
                2px 3px 2px #000,
                3px 2px 2px #000,
                3px 4px 2px #000,
                4px 3px 3px #000,
                4px 5px 3px #000,
                5px 4px 2px #000,
                5px 6px 2px #000,
                6px 5px 2px #000,
                6px 7px 1px #000,
                7px 6px 1px #000,
                7px 8px 0px #000,
                8px 7px 0px #000;
            &:hover{
                background-color: #00a850;
            }
        }
        .launch.amsterdam .launch-amsterdam-images{
            display: flex;
            justify-content: space-between;
            @media screen and (max-width:  1024px){
                display: block;
            }
            p{
                flex: 45% 0 0;
                img{
                    width:  100%;
                }
            }
        }
        img{
            max-width: 100%;
            display: block;
        }
        p{
            margin:  1em 0;
        }
        a:link,
        a:visited{
            color: #aa443c;
            &:hover{
                color:  #fff;
                background: #aa443c;
            }
        }
    }
</style>